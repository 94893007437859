var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loadingChangeReceivedQty)?_c('loading'):_vm._e(),_c('base-modal',{attrs:{"showing":_vm.visible && !_vm.packetComplainModal.visible},on:{"close":_vm.onClose,"opened":_vm.onOpened}},[_c('base-card',{attrs:{"with-style":false,"title":"Detail Koli","description":"Data detail koli"}},[_c('div',{staticClass:"grid gap-6 border-t pt-5 sm:grid-cols-2"},[_c('div',[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("Kode")]),_c('dd',{staticClass:"mt-1 text-sm font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm.packet.code)+" ")])]),_c('div',[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("Nama")]),_c('dd',{staticClass:"mt-1 text-sm font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm.packet.name)+" ")])]),_c('div',{staticClass:"col-span-full"},[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("Barang")]),_c('dd',{staticClass:"mt-1"},[_c('datatable',{attrs:{"columns":_vm.productColumns},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return [_vm._l((_vm.packet.products),function(product,index){return [_c('tr',{key:index,class:classes.tr},[_c('td',{class:classes.td},[_c('div',{staticClass:"flex items-center gap-2"},[(
                            product.product_qrcode_active ||
                            product.batch_has_qr
                          )?_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:qr-code"}}):_vm._e(),_vm._v(" "+_vm._s(product.product_code)+" ")],1)]),_c('td',{class:classes.td},[_vm._v(_vm._s(product.product_name))]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(product.shipped_product_qty)+" ")]),(_vm.withReceiveInput)?_c('td',{class:[classes.td, 'text-right']},[(
                          _vm.stockMovement.data.attributes.is_received ||
                          product.product_qrcode_active ||
                          product.batch_has_qr
                        )?_c('span',[_vm._v(_vm._s(product.received_product_qty))]):_c('input',{staticClass:"border-0 p-0 text-right text-sm focus:ring-0",attrs:{"type":"text"},domProps:{"value":product.received_product_qty},on:{"change":(e) => _vm.onChangeReceivedQty(e, index)}})]):_vm._e(),(false && _vm.withComplainForm)?_c('td',{class:[classes.td, 'text-right']},[_c('button',{staticClass:"ml-auto flex items-center justify-end",on:{"click":function($event){return _vm.onToggleComplain(index)}}},[_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":_vm.visibleComplainIds.includes(index)
                              ? 'heroicons:chevron-down'
                              : 'heroicons:chevron-right'}})],1)]):_vm._e()]),(
                      false &&
                      _vm.withComplainForm &&
                      _vm.visibleComplainIds.includes(index)
                    )?_c('tr',{key:`${index}-complain-form`,class:[classes.tr, 'bg-gray-50']},[_c('td'),_c('td',{class:classes.td},[_vm._v("Jumlah Komplain")]),_c('td',{class:classes.td},[_c('div',{staticClass:"flex justify-end"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.complains[index].qty),expression:"complains[index].qty"}],staticClass:"m-0 w-auto border-0 bg-transparent p-0 text-right text-sm focus:ring-0",attrs:{"type":"text","placeholder":"0","readonly":_vm.packet.complained},domProps:{"value":(_vm.complains[index].qty)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.complains[index], "qty", $event.target.value)}}})])]),_c('td')]):_vm._e()]})]}}])})],1)]),(false && (_vm.withComplainForm || _vm.packet.complained))?_c('div',{staticClass:"col-span-full flex justify-end"},[_c('base-button',{attrs:{"disabled":!_vm.packet.complained && !_vm.hasCompain},on:{"click":_vm.onSubmitComplain}},[_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:chat-bubble-left-ellipsis-solid"}}),_vm._v(" "+_vm._s(_vm.packet.complained ? 'Lihat Komplain' : 'Ajukan Komplain')+" ")],1)],1):_vm._e()])])],1),_c('view-packet-complain-modal',{attrs:{"packet":_vm.packet,"products":_vm.complains,"visible":_vm.packetComplainModal.visible},on:{"close":function($event){_vm.packetComplainModal.visible = false},"complained":_vm.onComplained}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }