<template>
  <div>
    <loading v-if="loadingChangeReceivedQty" />
    <base-modal
      :showing="visible && !packetComplainModal.visible"
      @close="onClose"
      @opened="onOpened"
    >
      <base-card
        :with-style="false"
        title="Detail Koli"
        description="Data detail koli"
      >
        <div class="grid gap-6 border-t pt-5 sm:grid-cols-2">
          <div>
            <dt class="text-xs text-gray-700">Kode</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ packet.code }}
            </dd>
          </div>
          <div>
            <dt class="text-xs text-gray-700">Nama</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ packet.name }}
            </dd>
          </div>
          <div class="col-span-full">
            <dt class="text-xs text-gray-700">Barang</dt>
            <dd class="mt-1">
              <datatable :columns="productColumns">
                <template #tbody="{ classes }">
                  <template v-for="(product, index) in packet.products">
                    <tr :key="index" :class="classes.tr">
                      <td :class="classes.td">
                        <div class="flex items-center gap-2">
                          <Icon
                            v-if="
                              product.product_qrcode_active ||
                              product.batch_has_qr
                            "
                            icon="heroicons:qr-code"
                            class="h-4 w-4"
                          />
                          {{ product.product_code }}
                        </div>
                      </td>
                      <td :class="classes.td">{{ product.product_name }}</td>
                      <td :class="[classes.td, 'text-right']">
                        {{ product.shipped_product_qty }}
                      </td>
                      <td
                        v-if="withReceiveInput"
                        :class="[classes.td, 'text-right']"
                      >
                        <span
                          v-if="
                            stockMovement.data.attributes.is_received ||
                            product.product_qrcode_active ||
                            product.batch_has_qr
                          "
                          >{{ product.received_product_qty }}</span
                        >
                        <input
                          v-else
                          type="text"
                          class="border-0 p-0 text-right text-sm focus:ring-0"
                          :value="product.received_product_qty"
                          @change="(e) => onChangeReceivedQty(e, index)"
                        />
                      </td>
                      <td
                        v-if="false && withComplainForm"
                        :class="[classes.td, 'text-right']"
                      >
                        <button
                          class="ml-auto flex items-center justify-end"
                          @click="onToggleComplain(index)"
                        >
                          <Icon
                            :icon="
                              visibleComplainIds.includes(index)
                                ? 'heroicons:chevron-down'
                                : 'heroicons:chevron-right'
                            "
                            class="h-4 w-4"
                          />
                        </button>
                      </td>
                    </tr>
                    <tr
                      v-if="
                        false &&
                        withComplainForm &&
                        visibleComplainIds.includes(index)
                      "
                      :key="`${index}-complain-form`"
                      :class="[classes.tr, 'bg-gray-50']"
                    >
                      <td></td>
                      <td :class="classes.td">Jumlah Komplain</td>
                      <td :class="classes.td">
                        <div class="flex justify-end">
                          <input
                            type="text"
                            class="m-0 w-auto border-0 bg-transparent p-0 text-right text-sm focus:ring-0"
                            placeholder="0"
                            :readonly="packet.complained"
                            v-model="complains[index].qty"
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </template>
              </datatable>
            </dd>
          </div>

          <div
            v-if="false && (withComplainForm || packet.complained)"
            class="col-span-full flex justify-end"
          >
            <base-button
              :disabled="!packet.complained && !hasCompain"
              @click="onSubmitComplain"
            >
              <Icon
                icon="heroicons:chat-bubble-left-ellipsis-solid"
                class="h-4 w-4"
              />
              {{ packet.complained ? 'Lihat Komplain' : 'Ajukan Komplain' }}
            </base-button>
          </div>
        </div>
      </base-card>
    </base-modal>

    <view-packet-complain-modal
      :packet="packet"
      :products="complains"
      :visible="packetComplainModal.visible"
      @close="packetComplainModal.visible = false"
      @complained="onComplained"
    />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import ViewPacketComplainModal from './view-packet-complain-modal.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    ViewPacketComplainModal,
  },
  props: {
    visible: Boolean,
    packet: Object,
    stockMovement: Object,
    withComplainForm: {
      type: Boolean,
      default: true,
    },
    withReceiveInput: Boolean,
  },
  emits: ['close', 'complained'],
  data() {
    return {
      reload: true,
      visibleComplainIds: [],
      packetComplainModal: {
        visible: false,
        packetId: null,
      },
      complains: [],
      loadingChangeReceivedQty: false,
    };
  },
  computed: {
    hasCompain() {
      return this.complains.filter(
        (complain) => complain.qty && parseInt(complain.qty) > 0
      ).length;
    },
    productColumns() {
      return [
        { id: 'code', name: 'Kode' },
        { id: 'name', name: 'Nama' },
        { id: 'shipped_qty', name: 'Jumlah Dikirim', theadClass: 'text-right' },
        ...(this.withReceiveInput
          ? [
              {
                id: 'received_qty',
                name: 'Jumlah Diterima',
                theadClass: 'text-right',
              },
            ]
          : []),
        // ...(false && this.withComplainForm ? [{ id: 'action', name: '', theadClass: 'text-right' }] : []),
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onClose() {
      this.reload = true;
      this.$emit('close');

      if (this.withReceiveInput) {
        document.removeEventListener('barcode-scan', this.onQrScanned);
      }
    },
    onOpened() {
      if (this.reload) {
        this.visibleComplainIds = [];

        this.complains = this.packet.products.map((product) => ({
          productId: product.product_id,
          productCode: product.product_code,
          qty: product.complained_product_qty,
        }));

        if (this.withReceiveInput) {
          document.addEventListener('barcode-scan', this.onQrScanned);
        }
      }
    },
    async onQrScanned(e) {
      this.loadingChangeReceivedQty = true;

      const [res, err] = await this.request(
        `/api/v1/packets/${this.packet.uuid}/-actions/scan-qrcode`,
        {
          method: 'post',
          data: {
            qrcode: e.detail.text,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        const productIndex = this.packet.products.findIndex(
          (product) => product.product_id === res.product_uuid
        );

        this.packet.products[productIndex].received_product_qty += res.qty;
      }

      this.loadingChangeReceivedQty = false;
    },
    onSubmitComplain() {
      this.reload = false;
      this.packetComplainModal.visible = true;
    },
    onToggleComplain(index) {
      if (this.visibleComplainIds.includes(index)) {
        this.visibleComplainIds.splice(
          this.visibleComplainIds.findIndex((i) => i === index),
          1
        );
      } else {
        this.visibleComplainIds.push(index);
      }
    },
    onComplained() {
      this.packetComplainModal.visible = false;
      this.$emit('complained');
    },
    async onChangeReceivedQty(e, index) {
      const { value } = e.target;
      const product = this.packet.products[index];

      if (isNaN(value)) {
        e.target.value = product.received_product_qty;
      } else {
        const qty = Number(value);

        if (qty < 0 || qty > product.shipped_product_qty) {
          e.target.value = product.received_product_qty;
        } else {
          const success = await this.submitReceivedQty(index, e.target.value);

          if (success) {
            this.packet.products[index].received_product_qty = e.target.value;
          } else {
            e.target.value = product.received_product_qty;
          }
        }
      }
    },
    async submitReceivedQty(index, value) {
      this.loadingChangeReceivedQty = true;

      const movementDetail = this.packet.products[index];

      const [, err] = await this.request(
        `/api/v1/movement-details/${movementDetail.uuid}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'movement-details',
              id: movementDetail.uuid,
              attributes: {
                received_product_qty: Number(value),
              },
            },
          },
        }
      );

      if (err) {
        this.loadingChangeReceivedQty = false;

        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });

        return false;
      } else {
        this.loadingChangeReceivedQty = false;

        return true;
      }
    },
  },
};
</script>
